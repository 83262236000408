.email-link {
  display: flex;
  align-items: center;
  font-family: "Roboto Mono";
  color: rgb(0, 76, 118);
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;
}

.email-link:hover {
  color: rgb(3, 34, 40);
}

.contact-h4 {
  padding-left: 20px;
  font-size: 30px;
}

.contact-text {
  padding-left: 20px;
  font-family: "Roboto Mono";
  font-size: 14px;
  width: 77%;
}

@media only screen and (min-width: 900px) {
  .contact-h4 {
    padding-left: 20px;
    padding-top: 20px;
  }
}

:root {
  --fontSize-text-mobile: 14px;
  --fontSize-text-desktop: 14px;
  --fontSize-headline-mobile: 35px;
  --fontSize-headline-desktop: 38px;
}

h4 {
  font-family: "Sometype Mono";
  font-weight: 500;
  font-size: var(--fontSize-headline-mobile);
  margin: 0px 0px 0px 0px;
  padding-bottom: 10px;
  color: rgb(65, 65, 65);
}

p {
  font-family: "Roboto Mono";
  font-size: var(--fontSize-text-mobile);
  color: rgb(65, 65, 65);
}

p:last-child {
  margin-bottom: 0px;
}

.lines {
  font-size: 18px;
  margin-top: -15px;
  width: fit-content;
}

.border {
  min-width: 18px;
}

.section-wrapper {
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  width: 85vw;
  margin: 30px 0px 0px 20px;
  padding: 0px;
}

.other-work__wrapper {
  margin-top: 30px;
}

.p-under-headline {
  margin-top: 4px;
}

.p-under-headline:last-child {
  margin-bottom: 40px;
}

.work-headline {
  color: rgb(65, 65, 65);
  font-family: "Roboto Mono";
  font-size: var(--fontSize-text-mobile);
  font-weight: 600;
  margin-top: 20px;
}

.other__p-under-headline__0,
.other__p-under-headline__1,
.other__p-under-headline__2 {
  margin: 0px;
  padding-bottom: 30px;
}

.other__p-under-headline__3 {
  margin: 0px;
  padding-bottom: 0px;
}

.other__work-headline {
  display: flex;
  align-items: center;
  color: rgb(65, 65, 65);
  font-family: "Roboto Mono";
  font-size: var(--fontSize-text-mobile);
  font-weight: 600;
  cursor: pointer;
}

.education-section-container {
  display: flex;
  flex-direction: column;
  /* width: 80px; */
}

.project-wrapper {
  width: 80vw;
}
.project-text {
  width: 100%;
  padding: 2px 0px 20px 0px;
  color: rgb(65, 65, 65);
  font-family: "Roboto Mono";
  font-size: var(--fontSize-text-mobile);
}

.project-link {
  display: flex;
  align-items: center;
  font-family: "Roboto Mono";
  color: rgb(0, 76, 118);
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;
  padding-bottom: 10px;
}

.project-link:hover {
  color: rgb(3, 34, 40);
}

.project-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
}
.project-video {
  width: 80vw;
  border-radius: 14px;
  padding: 0px 0px 0px 0px;
}

@media only screen and (min-width: 900px) {
  h4 {
    font-size: var(--fontSize-headline-desktop);
    margin: 5px 0px 0px 0px;
  }

  p {
    font-size: var(--fontSize-text-desktop);
  }

  .lines {
    font-size: 20px;
    margin-top: -15px;
  }

  .border {
    min-width: 22px;
  }

  .section-wrapper {
    display: flex;
    gap: 40px;
    width: 80vw;
    margin: 40px 0px 0px 60px;
  }

  .work-headline {
    font-size: var(--fontSize-text-desktop);
    margin-top: 30px;
  }

  .other__work-headline {
    display: flex;
    align-items: center;
    font-size: var(--fontSize-text-desktop);
  }

  .project-text {
    width: 500px;
    padding: 4px 0px 20px 0px;
    font-size: var(--fontSize-text-desktop);
  }

  .project-link-wrapper {
    width: 500px;
  }
  .project-video {
    width: 500px;
    border-radius: 14px;
  }
}

@media only screen and (min-width: 1250px) {
  .education-section-container {
    display: flex;
    flex-direction: row;
    width: 80vw;
  }
}

.intro {
  display: flex;
  gap: 20px;
  width: 90vw;
  margin: 20px 0px 0px 20px;
}

.intro-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.intro__image-container {
  height: 150px;
}
.intro__image {
  height: calc(100% - 8px);
  border: 4px solid #1a1a1a;
}
.intro__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.intro__headline {
  justify-self: flex-end;
  font-family: "Sometype Mono";
  font-weight: 500;
  font-size: 30px;
  margin-top: 0px;
  color: rgb(65, 65, 65);
}
.intro__link {
  display: flex;
  align-items: center;
  font-family: "Roboto Mono";
  color: rgb(0, 76, 118);
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;
}

.intro__link:hover {
  color: rgb(3, 34, 40);
}

.intro__language {
  display: flex;
  justify-content: space-between;
  width: 120px;
  font-family: "Roboto Mono";
  font-size: 18px;
  cursor: pointer;
  padding-top: 15px;
}

@media only screen and (min-width: 900px) {
  .intro {
    display: flex;
    gap: 40px;
    width: 70vw;
    margin: 60px 0px 0px 60px;
  }
  .intro-wrapper {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  .intro__headline {
    margin-top: -8px;
  }

  .intro__language {
    padding-top: 0px;
  }
}

.PDF_intro {
  display: flex;
  gap: 50px;
  width: 70vw;
  padding: 50px;
}
.PDF_intro-wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.PDF_intro-image-container {
  height: 150px;
}
.PDF_intro-image {
  height: calc(100% - 8px);
  border: 4px solid #1a1a1a;
}
.PDF_intro-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.PDF_intro-headline {
  justify-self: flex-end;
  font-family: "Sometype Mono";
  font-weight: 500;
  font-size: 35px;
  margin-top: -8px;
  color: rgb(65, 65, 65);
}
.PDF_intro-link {
  display: flex;
  align-items: center;
  font-family: "Roboto Mono";
  color: rgb(0, 76, 118);
  padding-top: 2px;
}

.PDF_intro-language {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
  width: 120px;
  font-size: 18px;
  padding-top: 0px;
}

.PDF_h4 {
  font-weight: 500;
  margin: 5px 0px 0px 0px;
  padding-bottom: 10px;
  font-family: "Sometype Mono";
  font-size: 42px;
  color: rgb(65, 65, 65);
}

.PDF_p {
  font-size: 16px;
  font-family: "Roboto Mono";
  color: rgb(65, 65, 65);
}

.PDF_p:last-child {
  margin-bottom: 0px;
}

.lines {
  font-size: 20px;
  margin-top: -15px;
  width: fit-content;
}

.PDF_border {
  min-width: 30px;
}

.PDF_section-wrapper {
  display: flex;
  gap: 50px;
  width: 80vw;
  margin: 0px 0px 50px 50px;
}

.PDF_other-work-wrapper {
  margin-top: 30px;
  font-family: "Roboto Mono";
}

.PDF_p-under-headline {
  margin-top: 4px;
  font-size: 16px;
}

.PDF_p-under-headline:last-child {
  margin-bottom: 40px;
}

.PDF_work-headline {
  font-size: 16px;
  margin-top: 20px;
  font-family: "Roboto Mono";
  color: rgb(65, 65, 65);
}

.PDF_other-p-under-headline__0,
.PDF_other-p-under-headline__1,
.PDF_other-p-under-headline__2 {
  margin: 0px;
  padding-bottom: 30px;
  font-size: 16px;
}

.PDF_other-p-under-headline__3 {
  margin: 0px;
  padding-bottom: 0px;
  font-size: 16px;
}

.PDF_other-work-headline {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: rgb(65, 65, 65);
  font-family: "Roboto Mono";
}

.PDF_education-section-container {
  display: flex;
  width: 90vw;
  margin: 0px 0px 0px 0px;
}

.PDF_project-wrapper {
  width: 80vw;
}
.PDF_project-text {
  width: 500px;
  padding: 2px 0px 20px 0px;
  font-size: 16px;
}

.PDF_project-link {
  display: flex;
  align-items: center;
  /* font-weight: bold; */
  color: rgb(0, 76, 118);
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;
  padding-bottom: 10px;
}
.PDF_project-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}
.PDF_project-img {
  width: 500px;
  border-radius: 14px;
}
